import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  EAuthentificationStep,
  IAuthenticationContentProps,
} from "./AuthenticationContent";
import useFacebookConnectSdk, {
  IFacebookLoginResponse,
} from "@src/lib/FacebookConnect/useFacebookConnectSdk";
import useBackboneContext from "@src/backbone/provider/useBackboneContext";
import useReCaptcha from "@src/hooks/useLogin/useReCaptcha";
import useGoogleConnectSdk from "@src/lib/GoogleConnect/useGoogleConnectSdk";
import { useDispatch } from "react-redux";
import { useTranslation } from "@src/hooks/useTranslation";
import { useConfiguration } from "@src/hooks/query/useConfiguration";
import { EInputType, InputProps } from "design-system/src/Form/Input/Input";
import { IconName } from "design-system/src/lib/svgIcon/components";
import PasswordValidationMessage from "@src/components/feedback/AuthenticationContent/Form/PasswordValidationMessage";
import { MessageRenderFC } from "@src/components/feedback/AuthenticationContent/Form/InputRender";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import moment from "moment/moment";
import PhoneUtils from "@src/backbone/prototypes/phoneUtils";
import phoneUtils from "@src/backbone/prototypes/phoneUtils";
import PhoneNumberFormat from "@src/lib/libphonenumber/PhoneNumberFormat";
import { useAppSelector } from "@src/store/hooks";
import { GATSBY_CONF_MY_SUSHI_SHOP_ENABLED } from "@src/utils/constants";
import { AxiosError } from "axios";

type SignInFormData = {
  email: string;
  password: string;
};
type SignUpFormData = {
  email: string;
  password: string;
  password_confirm: string;
  firstname: string;
  lastname: string;
  phone: string;
  birthday: string;
};
type OptInFormData = {
  opt_in_cgv: boolean;
  opt_in_email: boolean;
  opt_in_sms: boolean;
};

export type FormRules = Omit<
  RegisterOptions<any, any>,
  "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
>;
export type FormData = SignUpFormData & SignInFormData & OptInFormData;
enum EOptInIds {
  CGV = "opt_in_cgv",
  EMAIL = "opt_in_email",
  SMS = "opt_in_sms",
}
export type CustomerField = {
  name: keyof SignUpFormData;
  label: string;
  icon?: IconName;
  type?: InputProps["type"];
  rules?: FormRules;
  MessageRender?: MessageRenderFC;
};

export const useAuthenticationLogic = (props: IAuthenticationContentProps) => {
  const { onClose, onSuccess, options } = props;
  const { step: optionStep, id_customer_comin } = { ...options };
  const [isLoading, setIsLoading] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [isSigningGoogle, setIsSigningGoogle] = useState(false);
  const [isSigningFacebook, setIsSigningFacebook] = useState(false);
  const [step, setStep] = useState<EAuthentificationStep>(
    EAuthentificationStep.DEFAULT
  );
  const [ignorePassword, setIgnorePassword] = useState(false);
  const { app, customer, forceUpdate } = useBackboneContext();
  const reCaptcha = useReCaptcha();
  const dispatch = useDispatch();
  const { t } = useTranslation("Account");
  const { getConfiguration } = useConfiguration();
  const resetPasswordLink = useAppSelector(
    (state) => state.auth.openAuthModal?.resetPasswordLink
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const stepFromQuery = queryParams.get("step");
    if (stepFromQuery !== step) {
      setStep(stepFromQuery as EAuthentificationStep);
    }
  }, []);

  const onLogin = useCallback(
    (result: { status: string; message: string; data?: any }) => {
      if (isSigning === true) {
        setIsSigning(false);
      }
      if (result?.status === "success") {
        if (result.data?.field_missing || result.data?.requireRGPD) {
          goToMissingFields();
        } else {
          forceUpdate();
          // Login success
          onSuccess?.();
          onClose?.(false);
        }
      } else if (result.message) {
        app.warn(result.message);
      } else {
        app.alert();
      }
    },
    [app, dispatch, isSigning, onClose, onSuccess, forceUpdate]
  );

  const closeSuccessModal = () => {
    onClose?.(false);
    onSuccess?.();
  };

  const shouldShowLoyaltyOptIn = () => {
    const shouldShow =
      GATSBY_CONF_MY_SUSHI_SHOP_ENABLED && !customer?.isComeIn();
    if (shouldShow) {
      clearErrors();
      setIsLoading(false);
      setStep(EAuthentificationStep.LOYALTY_OPT_IN);
    }
    return shouldShow;
  };

  const FacebookCallback = (response: IFacebookLoginResponse) => {
    const accessToken = response.authResponse?.accessToken;
    if (response.status === "connected" && !!accessToken) {
      setIsSigningFacebook(true);
      customer
        ?.loginFacebook(accessToken, reCaptcha)
        .then(onLogin)
        .finally(() => {
          setIsSigningFacebook(false);
          setIsLoading(false);
        });
    } else {
      setIsSigningFacebook(false);
      setIsLoading(false);
    }
  };

  const facebookConnect = useFacebookConnectSdk({
    callback: FacebookCallback,
  });

  const googleConnect = useGoogleConnectSdk();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    clearErrors,
    getValues,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      password: "",
      password_confirm: "",
      firstname: "",
      lastname: "",
      birthday: "",
      phone: "",
      opt_in_cgv: false,
      opt_in_email: false,
      opt_in_sms: false,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const {
      email,
      password,
      firstname,
      lastname,
      phone: inputPhone,
      birthday: inputBirthday,
      opt_in_cgv,
      opt_in_email,
      opt_in_sms,
    } = data;
    const birthday = moment(inputBirthday, "DD/MM/YYYY").format("DD/MM/YYYY");
    const phone = PhoneUtils.format(inputPhone, false, PhoneNumberFormat.E164);
    if (step === EAuthentificationStep.SIGN_IN_EMAIL) {
      setIsSigning(true);
      customer
        ?.login(data, reCaptcha)
        .catch((e) => {
          // TODO: alert error
          console.log("login error", e);
          setIsSigning(false);
        })
        .then(onLogin)
        .finally(() => {
          setIsSigning(false);
          setIsLoading(false);
        });
    } else if (step === EAuthentificationStep.RESET_PASSWORD_REQUEST) {
      await app.getCustomer().resetPassword({ email: email });
      setIsLoading(false);
      clearErrors();
      setStep(EAuthentificationStep.RESET_PASSWORD_SUCCESS);
    } else if (step === EAuthentificationStep.SIGN_UP) {
      app
        .getCustomer()
        .register(
          {
            firstname,
            lastname,
            birthday,
            email,
            phone,
            password_v2: password,
            signupPassword: password,
            optin: opt_in_cgv,
            sms: opt_in_sms,
            mail_alert: opt_in_email,
            checkyoung: "on",
            id_customer_comin,
          },
          reCaptcha
        )
        .then((result) => {
          if (result.status === "error") {
            app.banNotice(result.message);
          } else if (!shouldShowLoyaltyOptIn()) {
            closeSuccessModal();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (step === EAuthentificationStep.MISSING_FIELDS) {
      const saveDatas = {
        birthday,
        optin: opt_in_cgv ? 1 : 0,
        sms: opt_in_sms ? 1 : 0,
        mail_alert: opt_in_email ? 1 : 0,
        phone,
      };
      try {
        await customer?.save(saveDatas);
        if (!shouldShowLoyaltyOptIn()) {
          closeSuccessModal();
        }
      } catch (err: AxiosError | any) {
        const errorMessage = err?.response?.data?.error || "";
        if (errorMessage) {
          app.alert(errorMessage);
        } else {
          app.alert();
        }
        setIsLoading(false);
      }
    } else if (step === EAuthentificationStep.LOYALTY_OPT_IN) {
      try {
        await customer?.save({
          comein: true,
        });
        await app?.getCart()?.remoteFetch();
        setIsLoading(false);
        setStep(EAuthentificationStep.LOYALTY_OPT_IN_SUCCESS);
      } catch (err) {
        app.alert();
      }
    } else if (step === EAuthentificationStep.LOYALTY_OPT_IN_SUCCESS) {
      closeSuccessModal();
    } else if (step === EAuthentificationStep.NEW_PASSWORD_REQUEST) {
      try {
        await customer?.resetPassword({
          link: resetPasswordLink || "",
          password: password,
        });
        closeSuccessModal();
      } catch (err) {
        setIsLoading(false);
      }
    } else if (step === EAuthentificationStep.NEW_PASSWORD_SUCCESS) {
      closeSuccessModal();
    } else if (step === EAuthentificationStep.RESET_PASSWORD_SUCCESS) {
      setStep(EAuthentificationStep.SIGN_IN_EMAIL);
      setIsLoading(false);
    }
  });

  const onClickGoogle = () => {
    setIsSigningGoogle(true);
    setIsLoading(true);
  };

  const onClickFacebook = () => {
    setIsSigningFacebook(true);
    setIsLoading(true);
    facebookConnect.login();
  };
  const attachGoogleSignIn = useCallback(
    (button: HTMLButtonElement) => {
      googleConnect.attachGoogleSignIn(
        button,
        (id_token: string) => {
          console.log("Google Connect id_token", id_token);
          app
            .getCustomer()
            .loginGoogle(id_token, reCaptcha)
            .then(onLogin)
            .finally(() => {
              setIsSigningGoogle(false);
              setIsLoading(false);
            });
        },
        (error) => {
          setIsSigningGoogle(false);
          setIsLoading(false);
          console.log("on Google Connect error", error);
        }
      );
    },
    [app, googleConnect, onLogin, reCaptcha]
  );

  const onClickSignInEmail = () => {
    setStep(EAuthentificationStep.SIGN_IN_EMAIL);
  };

  const onClickSignUpEmail = () => {
    setStep(EAuthentificationStep.SIGN_UP);
  };

  const onClickForgetPassword = () => {
    setStep(EAuthentificationStep.RESET_PASSWORD_REQUEST);
  };

  const goToMissingFields = () => {
    phoneUtils.load().finally(() => {
      setValue("firstname", customer.getFirstname());
      setValue("lastname", customer.getLastname());
      setValue(
        "phone",
        phoneUtils.formatInternational(customer.getPhone() || "")
      );
      setValue("birthday", customer.formatBirthday("DD/MM/YYYY"));
      setStep(EAuthentificationStep.MISSING_FIELDS);
      clearErrors();
    });
  };

  const onForgotPassword = async () => {
    setIgnorePassword(true);
    await handleSubmit(
      async (data) => {
        const { email } = data;
        await app.getCustomer().resetPassword({ email: email });
      },
      () => {
        app.banNotice(t("Merci de rentrer un email valide", "Account"));
      }
    )();
    setIgnorePassword(false);
  };

  const formErrors: string[] = Object.values(errors).map(
    (err) => err.message || ""
  );

  const goBack = (step: EAuthentificationStep) => {
    setIsLoading(false);
    setStep(step);
    clearErrors();
  };

  // Sign Up & Missing Fields
  const isSignUp = step === EAuthentificationStep.SIGN_UP;
  const isSignInEmail = step === EAuthentificationStep.SIGN_IN_EMAIL;
  const isSubmitPassword = step === EAuthentificationStep.NEW_PASSWORD_REQUEST;

  const customerFields: CustomerField[] = useMemo(() => {
    const emailMessage = t("Merci de rentrer un email valide", "Account");
    const passwordField = {
      name: "password",
      label: t("Mot de passe", "Account"),
      icon: "padlock",
      type: EInputType.PASSWORD,
      rules: {
        required: t("Merci de rentrer un mot de passe", "Account"),
        ...(!isSignInEmail && {
          minLength: {
            value: 8,
            message: t(
              "Votre mot de passe doit contenir au moins %s caractères",
              "Account",
              { vsprintfArgs: [8] }
            ),
          },
          validate: (value: string) => {
            if (value === getValues("email")) {
              return t(
                "Votre mot de passe ne peut pas être identique à votre adresse e-mail",
                "Account"
              );
            }
            return true;
          },
        }),
      },
      MessageRender: isSignInEmail ? undefined : PasswordValidationMessage,
    };
    return [
      ...(isSignUp || isSignInEmail
        ? ([
            {
              name: "email",
              label: t("Adresse e-mail", "Account"),
              icon: "message",
              rules: {
                required: emailMessage,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: emailMessage,
                },
              },
            },
            passwordField,
          ] as CustomerField[])
        : []),
      ...(isSignInEmail
        ? []
        : isSubmitPassword
        ? ([
            passwordField,
            {
              ...passwordField,
              name: "password_confirm",
              label: t("Confirmation du mot de passe", "Account"),
              rules: {
                required: t("Merci de confirmer votre mot de passe", "Account"),
                validate: (value: string) =>
                  value === getValues("password") ||
                  t(
                    "Votre confirmation de mot de passe n’est pas identique",
                    "Account"
                  ),
              },
              MessageRender: undefined,
            },
          ] as CustomerField[])
        : ([
            {
              name: "firstname",
              label: t("Prénom", "Account"),
              icon: "user",
              rules: {
                required: true,
              },
            },
            {
              name: "lastname",
              label: t("Nom", "Account"),
              icon: "user",
              rules: {
                required: true,
              },
            },
            {
              name: "phone",
              label: t("Téléphone", "Account"),
              rules: {
                required: true,
              },
            },
            {
              name: "birthday",
              label: t("Date de naissance", "Account"),
              icon: "birthday",
              rules: {
                required: true,
                pattern: {
                  value: /^\d\d\/\d\d\/\d\d\d\d$/,
                  message: t("Veuillez respecter le format %s", "Account", {
                    vsprintfArgs: [t("jj/mm/aaaa", "Account")],
                  }),
                },
              },
            },
          ] as CustomerField[])),
    ];
  }, [isSignUp, isSignInEmail, isSubmitPassword]);

  // OptIns

  const _OPTIN_MENTIONS_TEXT_ = getConfiguration<string>(
    "_OPTIN_MENTIONS_TEXT_",
    ""
  );
  const _OPTIN_EMAIL_MENTIONS_TEXT_ = getConfiguration<string>(
    "_OPTIN_EMAIL_MENTIONS_TEXT_",
    ""
  );
  const _OPTIN_SMS_MENTIONS_TEXT_ = getConfiguration<string>(
    "_OPTIN_SMS_MENTIONS_TEXT_",
    ""
  );

  const optInFields: {
    id: EOptInIds;
    label: string;
    required?: boolean;
  }[] = [
    ...(!!_OPTIN_MENTIONS_TEXT_
      ? [
          {
            id: EOptInIds.CGV,
            label: _OPTIN_MENTIONS_TEXT_,
            required: true,
          },
        ]
      : []),
    ...(!!_OPTIN_EMAIL_MENTIONS_TEXT_
      ? [
          {
            id: EOptInIds.EMAIL,
            label: _OPTIN_EMAIL_MENTIONS_TEXT_,
          },
        ]
      : []),
    ...(!!_OPTIN_SMS_MENTIONS_TEXT_
      ? [
          {
            id: EOptInIds.SMS,
            label: _OPTIN_SMS_MENTIONS_TEXT_,
          },
        ]
      : []),
  ];

  useEffect(() => {
    // ouverture du Drawer
    if (optionStep && optionStep !== step) {
      if (optionStep === EAuthentificationStep.MISSING_FIELDS) {
        goToMissingFields();
      } else {
        setStep(optionStep);
      }
    }
  }, [optionStep]);

  return {
    control,
    onSubmit,
    isValid,
    isLoading,
    isSigning,
    isSigningGoogle,
    isSigningFacebook,
    isComeIn: false,
    step,
    setStep,
    goBack,
    closeSuccessModal,
    clearErrors,
    formErrors,
    attachGoogleSignIn,
    onClickGoogle,
    onClickFacebook,
    onClickSignInEmail,
    onClickSignUpEmail,
    onClickForgetPassword,
    ignorePassword,
    t,
    getValues,
    optInFields,
    customerFields,
  };
};

export type IUseAuthenticationLogic = ReturnType<typeof useAuthenticationLogic>;
